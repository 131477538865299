<template>
    <div>
        <div class="top-bar" id="topbar">
            <button class="btn" id ="prev-page10">
                Prev 10 Questions
            </button>
            <button class="btn" id ="prev-page">
                Prev Question
            </button>
            <button class="btn" id ="next-page">
                Next Question
            </button>
            
            <button class="btn" id ="next-page10">
                Next 10 Question
            </button>
            
            <span class="page-info">
                Question <span id="page-num"></span> of 
                <span id="page-count"></span>
            </span>

            <button class="btn" id ="testbtn">
                Test
            </button>
            
        </div>
    
        <div class="questionrender">
            <div class="questionurl" id="questionurl"></div>
            <div class="eempty" id="dropin1"></div>
            <div class="eempty" id="dropin2"></div>
            <div class="eempty" id="dropin3"></div>
            <div class="eempty" id="dropin4"></div>
            <div class="eempty" id="dropin5"></div>
            <div class="eempty" id="dropin6"></div>
            <div class="eempty" id="dropin7"></div>
            <div class="eempty" id="dropin8"></div>
            <div class="eempty" id="dropin9"></div>
            <div class="eempty" id="dropin10"></div>

            <div class="eempty" id="dropout1">
                <div class="ffill" id="answer1url" draggable="true"></div>
            </div>
            <div class="eempty" id="dropout2">
                <div class="ffill" id="answer2url" draggable="true"></div> 
            </div>
            <div class="eempty" id="dropout3">
                <div class="ffill" id="answer3url" draggable="true"></div>
            </div>
            <div class="eempty" id="dropout4">
                <div class="ffill" id="answer4url" draggable="true"></div>    
            </div>
            <div class="eempty" id="dropout5">
                <div class="ffill" id="answer5url" draggable="true"></div>    
            </div>
            <div class="eempty" id="dropout6">
                <div class="ffill" id="answer6url" draggable="true"></div>    
            </div>
            <div class="eempty" id="dropout7">
                <div class="ffill" id="answer7url" draggable="true"></div>    
            </div>
            <div class="eempty" id="dropout8">
                <div class="ffill" id="answer8url" draggable="true"></div>    
            </div>
            <div class="eempty" id="dropout9">
                <div class="ffill" id="answer9url" draggable="true"></div>    
            </div>
            <div class="eempty" id="dropout10">
                <div class="ffill" id="answer10url" draggable="true"></div>    
            </div>
            
            


        </div>
        
        
    






        
    </div>
</template>

<script>
//import {rrealdb,realref } from "~/plugins/firebase1.js";
//import { getDatabase, ref, child, get } from "firebase/database"
import { initializeApp, getApps } from "firebase/app"


//import {getStorage,uploadBytes,ref,getDownloadURL} from "firebase/storage"
import { getDatabase,child,get,ref as realref } from "firebase/database";



const firebaseConfig = {
    apiKey: "AIzaSyABbn7G44FE551dPGF-i5-paJHZsLLvRVg",
    authDomain: "vuefs-prod-b17eb.firebaseapp.com",
    projectId: "vuefs-prod-b17eb",
    storageBucket: "vuefs-prod-b17eb.appspot.com",
    messagingSenderId: "314256150864",
    appId: "1:314256150864:web:4502f5b12772baf2d71c55"
  
  };
  
  
  // Initialize Firebase
const apps = getApps();

if (!apps.length) {
    initializeApp(firebaseConfig);
    
    
} else {
    
    //const firebaseApp = apps[0]
    
}

//const storage = getStorage();
//const storageRef = ref(storage);
//const imagesRef = ref(storage, 'sparky.pdf');
//const sparkyRef = ref(storage,'images/sparky.pdf')

const rrealdb = getDatabase()



//export{storage,uploadBytes, ref, getDownloadURL,rrealdb,realref}


export default {
    mounted(){

        //This is the real relevant version

        const emdrop1 = document.querySelector('#dropout1');
        const emdrop2 = document.querySelector('#dropout2');
        const emdrop3 = document.querySelector('#dropout3');
        const emdrop4 = document.querySelector('#dropout4');
        const emdrop5 = document.querySelector('#dropout5');
        const emdrop6 = document.querySelector('#dropout6');
        const emdrop7 = document.querySelector('#dropout7');
        const emdrop8 = document.querySelector('#dropout8');
        const emdrop9 = document.querySelector('#dropout9');
        const emdrop10 = document.querySelector('#dropout10');
       

        //draggable for real
        //draggable div
        const fill1r = document.querySelector('#answer1url');
        const fill2r = document.querySelector('#answer2url');
        const emptiesr = document.querySelectorAll('.eempty');

        var fillr;

        fill1r.addEventListener('dragstart',deffill1r); 
        fill2r.addEventListener('dragstart',deffill2r);

        fill1r.addEventListener('dragstart',dragStartr); 
        fill1r.addEventListener('dragend',dragEndr); 
        
        fill2r.addEventListener('dragstart',dragStartr); 
        fill2r.addEventListener('dragend',dragEndr); 

    
        function deffill1r(){
            fillr = fill1r
        }

        function deffill2r(){
            fillr = fill2r
        }

        //new

        const fill3r = document.querySelector('#answer3url');
        const fill4r = document.querySelector('#answer4url');
        const fill5r = document.querySelector('#answer5url');
        const fill6r = document.querySelector('#answer6url');
        const fill7r = document.querySelector('#answer7url');
        const fill8r = document.querySelector('#answer8url');
        const fill9r = document.querySelector('#answer9url');
        const fill10r = document.querySelector('#answer10url');

        fill3r.addEventListener('dragstart',deffill3r); 
        fill4r.addEventListener('dragstart',deffill4r);
        fill5r.addEventListener('dragstart',deffill5r);
        fill6r.addEventListener('dragstart',deffill6r);
        fill7r.addEventListener('dragstart',deffill7r);
        fill8r.addEventListener('dragstart',deffill8r);
        fill9r.addEventListener('dragstart',deffill9r);
        fill10r.addEventListener('dragstart',deffill10r);
        

        fill3r.addEventListener('dragstart',dragStartr); 
        fill3r.addEventListener('dragend',dragEndr); 
        
        fill4r.addEventListener('dragstart',dragStartr); 
        fill4r.addEventListener('dragend',dragEndr);

        fill5r.addEventListener('dragstart',dragStartr); 
        fill5r.addEventListener('dragend',dragEndr);

        fill6r.addEventListener('dragstart',dragStartr); 
        fill6r.addEventListener('dragend',dragEndr);

        fill7r.addEventListener('dragstart',dragStartr); 
        fill7r.addEventListener('dragend',dragEndr);

        fill8r.addEventListener('dragstart',dragStartr); 
        fill8r.addEventListener('dragend',dragEndr);

        fill9r.addEventListener('dragstart',dragStartr); 
        fill9r.addEventListener('dragend',dragEndr);

        fill10r.addEventListener('dragstart',dragStartr); 
        fill10r.addEventListener('dragend',dragEndr);


        function deffill3r(){
            fillr = fill3r
        }

        function deffill4r(){
            fillr = fill4r
        }
        function deffill5r(){
            fillr = fill5r
        }
        function deffill6r(){
            fillr = fill6r
        }
        function deffill7r(){
            fillr = fill7r
        }
        function deffill8r(){
            fillr = fill8r
        }
        function deffill9r(){
            fillr = fill9r
        }
        function deffill10r(){
            fillr = fill10r
        }


        




        //Fill Listeners
         
        
        //Loop through empties and call drag events
        for(const emptyr of emptiesr){
            emptyr.addEventListener('dragover', dragOverr);
            emptyr.addEventListener('dragenter', dragEnterr);
            emptyr.addEventListener('dragleave', dragLeaver);
            emptyr.addEventListener('drop', dragDropr);
        }
        //Drag Functions
        function dragStartr(){
            
            this.className += ' hold';
            setTimeout(()=> this.className = 'invisible',0);
            
            
        }
        function dragEndr(){
            
            this.className = 'ffill';
            
            
        }

        function dragOverr(e){
            e.preventDefault();
            
        }
        function dragEnterr(e){
            e.preventDefault();
            
            this.className += ' hovered';
            
            
        }
        function dragLeaver(){
            
            this.className = "eempty";
           
        }
        function dragDropr(){
            
            this.className = 'eempty';
            
            this.append(fillr);
            
        }

        //put into original position
        function orposition(){
            for (let i=0; i<10;i++){
                if (prevavanswers[i]){
                    if (i==0){
                        emdrop1.append(fill1r);
                    }
                    else if (i==1){
                        emdrop2.append(fill2r);
                    }
                    else if (i==2){
                        emdrop3.append(fill3r);
                    }
                    else if (i==3){
                        emdrop4.append(fill4r);
                    }
                    else if (i==4){
                        emdrop5.append(fill5r);
                    }
                    else if (i==5){
                        emdrop6.append(fill6r);
                    }
                    else if (i==6){
                        emdrop7.append(fill7r);
                    }
                    else if (i==7){
                        emdrop8.append(fill8r);
                    }
                    else if (i==8){
                        emdrop9.append(fill9r);
                    }
                    else if (i==9){
                        emdrop10.append(fill10r);
                    }
                    
                }
                    
            }
        }

        

        //load from database


        //general
        let baseorientation = ["answer1url","answer2url","answer3url","answer4url","answer5url","answer6url","answer7url","answer8url","answer9url","answer10url"]
        let dropoutorient =["dropout1","dropout2","dropout3","dropout4","dropout5","dropout6","dropout7","dropout8","dropout9","dropout10"];
        let dropinorient=["dropin1","dropin2","dropin3","dropin4","dropin5","dropin6","dropin7","dropin8","dropin9","dropin10"]
        
        
        //refreshing
        let boxn = ["","","","","","","","","",""];
        
        let locc = new Array;
        
        var minheight = 1000;
        var minwidth = 1000;
        var maxwidth = 1;
        var maxheight =1;
    
        let targetscalew = new Array;
        let targetscaleh = new Array;
        
        let avanswers =[0,0,0,0,0,0,0,0,0,0];
        let prevavanswers = new Array;
        var ansurl = ["","","","","","","","","",""] ;


        //temporary
        let slidenumber = 14;
        //var finalextraction = "FinancialReporting/"+slidenumber+"/boxnumber"
        
        
        

        var questionurl ="";
        
        
        function writeUserData() {
            

            



            const rrrref= realref(rrealdb)
            var finalextraction = "FinancialReporting/slide"+slidenumber+"/boxnumber"
            //var finalextraction = "FinancialReporting/slide1/boxnumber"

            
            function extract(){
                //console.log("outside"+finalextraction)
                for (let i=0; i<3;i++){
                    if (i==0){
                        get(child(rrrref, finalextraction)).then((snapshot) => {
                            if (snapshot.exists()) {
                                boxn=snapshot.val();
                                //console.log(boxn)
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                    }
                    else if (i==1){
                        finalextraction = "FinancialReporting/slide"+slidenumber+"/location";
                        get(child(rrrref, finalextraction)).then((snapshot) => {
                            if (snapshot.exists()) {
                                locc=snapshot.val();
                                //console.log(locc)
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                    }
                    else if (i==2){
                        finalextraction = "FinancialReporting/slide"+slidenumber+"/questionurl";
                        get(child(rrrref, finalextraction)).then((snapshot) => {
                            if (snapshot.exists()) {
                                questionurl=snapshot.val();
                                
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                    }
                }
    
                
            }
            
            function getavailableanswers(){
                for (let i=0; i<10;i++){
                    if (locc[i][2] ==15){
                        avanswers[i]=false
                    }else if (locc[i][2] >15){
                        avanswers[i]=true 
                    }else{
                        avanswers[i]=false
                    }
                }
                prevavanswers=avanswers
                //console.log(avanswers)
            }

            function extractanswers(){
                for (let i=0;i<10;i++){
                    finalextraction = "FinancialReporting/slide"+slidenumber+"/"+baseorientation[i];
                    if (avanswers[i]){
                        get(child(rrrref, finalextraction)).then((snapshot) => {
                        
                            if (snapshot.exists()) {
                                ansurl[i]=snapshot.val(); 
                                //console.log(snapshot.val());
                                

        
                            } else {
                                console.log("No data available");
                            }
                            
                        }).catch((error) => {
                            console.error(error);
                        }) 
                    }else{
                        ansurl[i]=""
                    }
                           
                }    
            }
            
            setTimeout(function() { extract(); }, 100);  
            setTimeout(function() { getavailableanswers(); }, 800);
            setTimeout(function() { extractanswers(); }, 1200);
            
        }

        function ttest(){
            //console.log("ttest")
            let ii;
            for (let i=0;i<10;i++){
                //console.log("test")
                if (avanswers[i]){
                    //console.log("hello")
                    ii = i+1;
                    //console.log('dropin'+ii)

                    //toptop is necessary because of the topbar during extraction
                    //console.log(locc[i][0]+" "+locc[i][2]+" "+locc[i][3])
                    var leftleft=locc[i][0]+(locc[i][2]-minwidth)/2;
                    var toptop=locc[i][1]+(locc[i][3]-minheight)/2-69.7;
                    //console.log("ii"+ii)
                    //console.log("i"+i)
                    //console.log("minwidth"+minwidth);
                    //console.log("minwidth"+minheight)
                    document.getElementById('dropin'+ii).style.width=minwidth+3+'px';
                    document.getElementById('dropin'+ii).style.backgroundColor= 'rgb(141,182,205,0.5)';
                    document.getElementById('dropin'+ii).style.height=minheight+3+'px';
                    document.getElementById('dropin'+ii).style.left=leftleft-1.5+'px';
                    document.getElementById('dropin'+ii).style.top=toptop-1.5+'px';
                    
                    //document.getElementById('dropin'+ii).style.top='595px';
                    

                }else{
                    ii = i+1;
                    document.getElementById('dropin'+ii).style.width='1px';
                    document.getElementById('dropin'+ii).style.backgroundColor= 'transparent';
                    document.getElementById('dropin'+ii).style.height='1px';
                    document.getElementById('dropin'+ii).style.left='0px';
                    document.getElementById('dropin'+ii).style.top='0px';

                    
                }
            }
            
        }



        function writepicture(){
            document.getElementById('questionurl').style.backgroundImage = "url('"+questionurl+"')";
            document.getElementById('questionurl').style.width='720px';
            document.getElementById('questionurl').style.height='540px';
            document.getElementById('questionurl').style.top='0px';
            
            
        }
        function writeanswers(){
            
            for (let i=0;i<10;i++){
                
                if (avanswers[i]){
                    //console.log("true")
                    //new
                    
                    //document.getElementById(dropoutorient[i]).style.borderColor='black';
                    //document.getElementById(dropoutorient[i]).style.borderWidth='medium';

                    document.getElementById(dropoutorient[i]).style.width=minwidth+2+'px';
                    document.getElementById(dropoutorient[i]).style.height=minheight+2+'px';
                    document.getElementById(dropoutorient[i]).style.left=minwidth*i+'px';
                    document.getElementById(dropoutorient[i]).style.backgroundColor= 'white';

                    //document.getElementById(baseorientation[i]).style.backgroundSize= targetscalew[i]+'% '+targetscaleh[i]+'%';
                    document.getElementById(baseorientation[i]).style.backgroundSize= '100% '+' 100%'

                    document.getElementById(baseorientation[i]).style.width=minwidth+'px';
                    document.getElementById(baseorientation[i]).style.backgroundColor= 'white';
                    document.getElementById(baseorientation[i]).style.height=minheight+'px';
                    
                    document.getElementById(baseorientation[i]).style.backgroundImage = "url('"+ansurl[i]+"')";

                    
                }else{
                    //console.log("false")
                    //document.getElementById(dropoutorient[i]).style.borderColor='transparent';
                    //document.getElementById(dropoutorient[i]).style.borderWidth='medium';
                    document.getElementById(dropoutorient[i]).style.width = '1px';
                    document.getElementById(dropoutorient[i]).style.height = '1px';
                    document.getElementById(dropoutorient[i]).style.left = '0px';
                    document.getElementById(dropoutorient[i]).style.backgroundColor= 'transparent';

                    document.getElementById(baseorientation[i]).style.backgroundImage = 'none';
                    document.getElementById(baseorientation[i]).style.width = '1px';
                    document.getElementById(baseorientation[i]).style.height = '1px';
                    document.getElementById(baseorientation[i]).style.backgroundColor= 'transparent';
                    
                    

                    


                    
                }
            }
           
        }
        




        function getdimensions(){
            minheight = 1000;
            minwidth = 1000;
            maxwidth = 1;
            maxheight =1;
            for (let i=0;i<10;i++){
                
                if (avanswers[i]){
                    if (minheight>locc[i][3] && locc[i][3] >14){
                        
                        minheight=locc[i][3];   
                    }
                    if (minwidth>locc[i][2] && locc[i][2] >14) {
                        
                        minwidth=locc[i][2];   
                    }
                    if (maxheight<locc[i][3] && locc[i][3] >14){
                        
                        maxheight=locc[i][3];   
                    }
                    if (maxwidth<locc[i][2] && locc[i][2] >14) {
                        
                        maxwidth=locc[i][2];   
                    }
                }
                
                
                
                

            }
            //console.log(locc)
            //console.log("minwidth"+minwidth);
            //console.log("minwidth"+minheight)
            for (let i=0;i<10;i++){
                if (avanswers[i]){
                    targetscalew[i] = minwidth/locc[i][2]*100
                    targetscaleh[i] = minheight/locc[i][3]*100 
                    
                }else{
                    targetscalew[i] = 100
                    targetscaleh[i] = 100
                }
                
                
                
                

            }
            //console.log("ttest3")
            
            

            
            
            
        }
        
       
        


        


        

        

        


        function runprogram(){
            setTimeout(function() { writeUserData(); }, 100);
            setTimeout(function() { writepicture(); }, 1200);
            
            setTimeout(function() { getdimensions(); }, 1300);
            
            setTimeout(function() { writeanswers(); }, 1500);
            
            setTimeout(function() { ttest(); },1500 ); 
        }

        let ppage;
        function getpagerelevantpages(){
            const rrrref= realref(rrealdb)
            get(child(rrrref, "FinancialReporting/total/avslidespages")).then((snapshot) => {
                    if (snapshot.exists()) {
                        ppage=snapshot.val();
                        //console.log(ppage)
                        totalquestionnumber =ppage.length
                        document.querySelector('#page-num').textContent = 1;
                        document.querySelector('#page-count').textContent = totalquestionnumber;
                        slidenumber = ppage[0]+1
                        //console.log("slidenumber"+slidenumber)
                        
                    } else {
                        console.log("No data available");
                    }
                }).catch((error) => {
                    console.error(error);
                })
            
        }

        getpagerelevantpages();
        setTimeout(function() { runprogram(); }, 500);
        





        
        let pageNum = 1;
        

            
            
            

            
        let totalquestionnumber;
        const showPrevPage = ()=>{
            if(pageNum<=1){
                return;
            }
            orposition();
            pageNum--;
            //console.log("showPrevPage")
            queueRenderPage(pageNum);
            slidenumber = ppage[pageNum-1]+1;
            runprogram()
            document.querySelector('#testbtn').textContent = "Test"
            
        }

        const showNextPage = ()=>{
            if(pageNum>=totalquestionnumber){
                return;
            }
            orposition();
            //console.log(prevavanswers)
            
            pageNum++;
            //console.log("showNextPage")
            queueRenderPage(pageNum);
            slidenumber = ppage[pageNum-1]+1;
            runprogram()
            document.querySelector('#testbtn').textContent = "Test"
        }



        const showPrevPage10 = ()=>{
            if(pageNum<=10){
                return;
            }
            orposition();
            pageNum= pageNum-10;
            //console.log("showPrevPage")
            queueRenderPage(pageNum);
            slidenumber = ppage[pageNum-1]+1;
            runprogram()
            document.querySelector('#testbtn').textContent = "Test"
            
        }

        const showNextPage10 = ()=>{
            if(pageNum+10>=totalquestionnumber){
                return;
            }
            orposition();
            //console.log(prevavanswers)
            
            pageNum=pageNum+10;
            //console.log("showNextPage")
            queueRenderPage(pageNum);
            slidenumber = ppage[pageNum-1]+1;
            runprogram()
            document.querySelector('#testbtn').textContent = "Test"
        }




        function queueRenderPage(num){
            //console.log("queueRenderPage")
            document.querySelector('#page-num').textContent = num;
            document.querySelector('#page-count').textContent = totalquestionnumber;
        }


        document.querySelector('#prev-page').addEventListener('click',showPrevPage);
        document.querySelector('#next-page').addEventListener('click',showNextPage);
        document.querySelector('#prev-page10').addEventListener('click',showPrevPage10);
        document.querySelector('#next-page10').addEventListener('click',showNextPage10);





        function checkanswers(){
            
            //console.log("checkanswers")
            function coloranswer(boxboxbox){
                let boxbox =boxn[boxboxbox]
                let posboxes = []
                let posboxesnumb = 0
                let boxescheckcheck = false
                let dropinn = dropinorient[boxboxbox]
                //let aanswer = baseorientation[boxboxbox]
                for(let i=0;i<10;i++){
                    if (boxbox==boxn[i]){
                        posboxes[posboxesnumb]=baseorientation[i];
                        posboxesnumb +=1;
                    }
            
                }
                for (let i=0;i<posboxesnumb;i++){
                    var parentDiv = document.getElementById(dropinn);
                    var childDiv = document.getElementById(posboxes[i]);
                    if (parentDiv.contains(childDiv)) {
                        //console.log("yes");
                        document.getElementById(dropinn).style.backgroundColor= 'green';
                        document.getElementById(dropinn).style.padding = "3px 3px 3px 3px";
                        boxescheckcheck = true
                    }
                }    
                if(!boxescheckcheck){

                    //console.log("no");
                    document.getElementById(dropinn).style.backgroundColor= 'red';
                    document.getElementById(dropinn).style.padding = "3px 3px 3px 3px";
                }
                
                



                //var parentDiv = document.getElementById(dropin);
                //var childDiv = document.getElementById(answer);
                //if (parentDiv.contains(childDiv)) {
                //    console.log("yes");
                //    document.getElementById(dropin).style.backgroundColor= 'green';
                //    document.getElementById(dropin).style.padding = "3px 3px 3px 3px";
                //}
                //else{
                //    console.log("no");
                //    document.getElementById(dropin).style.backgroundColor= 'red';
                //    document.getElementById(dropin).style.padding = "3px 3px 3px 3px";
                //}
            }
            for (let i=0; i<10;i++){
                if (avanswers[i]){
                    if (i==0){
                        //coloranswer("dropin1","answer1url")
                        coloranswer(i)
                        
                    }
                    else if (i==1){
                        //coloranswer("dropin2","answer2url")
                        coloranswer(i)
                    }
                    else if (i==2){
                        //coloranswer("dropin3","answer3url")
                        coloranswer(i)
                    }
                    else if (i==3){
                        //coloranswer("dropin4","answer4url")
                        coloranswer(i)
                    }
                    else if (i==4){
                        //coloranswer("dropin5","answer5url")
                        coloranswer(i)
                    }
                    else if (i==5){
                        //coloranswer("dropin6","answer6url")
                        coloranswer(i)
                    }
                    else if (i==6){
                        //coloranswer("dropin7","answer7url")
                        coloranswer(i)
                    }
                    else if (i==7){
                        //coloranswer("dropin8","answer8url")
                        coloranswer(i)
                    }
                    else if (i==8){
                        //coloranswer("dropin9","answer9url")
                        coloranswer(i)
                    }
                    else if (i==9){
                        //coloranswer("dropin10","answer10url")
                        coloranswer(i)
                    }
                    
                }
            }
            document.querySelector('#testbtn').textContent = "Try Again"
        }



        function doublebutton(){
            let bcontent = document.querySelector('#testbtn').textContent;
            
            if (bcontent.trim() =="Test"){
                checkanswers();
                //console.log("Answers are being checked");
            } 
            else if(bcontent.trim() =="Try Again"){
                //console.log("Try again")
                orposition();
                runprogram();
                document.querySelector('#testbtn').textContent = "Test"
            }
            //console.log("button works")
        }




        document.querySelector('#testbtn').addEventListener('click',doublebutton);

        





    }
    

    //background: rgba(64, 224, 208, 0.3)
}
</script>

<style>

body{
    background: rgb(141,182,205,0.5);

}
#questionurl{
    top:0; left:0; height:1; width:1;
}
#answer1url{
    top:0; left:0; height:1; width:1;
}
#answer2url{
    top:0; left:0; height:1; width:1;
}
#answer3url{
    top:0; left:0; height:1; width:1;
}
#answer4url{
    top:0; left:0; height:1; width:1;
}
#answer5url{
    top:0; left:0; height:1; width:1;
}
#answer6url{
    top:0; left:0; height:1; width:1;
}
#answer7url{
    top:0; left:0; height:1; width:1;
}
#answer8url{
    top:0; left:0; height:1; width:1;
}
#answer9url{
    top:0; left:0; height:1; width:1;
}
#answer10url{
    top:0; left:0; height:1; width:1;
}

.questionrender{position:relative;}

#dropin1{
    top:0; left:0; height:1; width:1; position: absolute; 
}
#dropin2{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin3{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin4{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin5{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin6{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin7{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin8{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin9{
    top:0; left:0; height:1; width:1; position: absolute;
}
#dropin10{
    top:0; left:0; height:1; width:1; position: absolute;
}



#dropout1{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout2{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout3{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout4{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout5{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout6{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout7{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout8{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout9{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
}
#dropout10{
    top:0; left:0; height:1; width:1; margin: 10px; display: inline-block; background-color: white; border: 3px black;
    
}







.fill{
    background-image: url('http://source.unsplash.com/random/150*150');
    position: relative;
    height: 150px;
    width: 150px;
    top: 5px;
    left: 5px;
    cursor: pointer;
}



.empty{
    display: inline-block;
    height: 160px;
    width: 160px;
    margin: 10px;
    border: 3px white;
    background-color: white;


}

.hold{
    border: solid black 4px;
}
.hovered{
    background: #f4f4f4;
    border-style: dashed;

}

.invisible{
    display:none;
}




.top-bar{
    background: rgb(102, 102, 102);
    color: #fff;
    padding: 1rem;
}
.btn{
    background: rgb(141,182,205);
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.7rem 2rem;
    margin: 2px;
}
.btn:hover{
    opacity:0.9;
}



.page-info{
    margin-left: 1rem;
}

*{
    margin: 0;
    padding: 0;
}
</style>
